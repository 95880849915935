import ErrorPage from '@/UI/Error';
import { Sad404Teddy } from '@/UI/Icons';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

export default function NotFound() {
  const {t} = useTranslation()
  return (
    <ErrorPage
      statusCode="404"
      title="404: This page could not be found"
      text={t('pages.not_found')}
      image={<Sad404Teddy />}
    />
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale))
    },
  }
}
